export const POST_TYPE_NORMAL = 'normal';
export const POST_TYPE_LONGFORM = 'longform';
export const POST_TYPE_BRANDED = 'branded';
export const POST_TYPE_BRAND_ARTICLE = 'brandArticle';
export const POST_TYPE_BRAND_ADVERTISING = 'brandAdvertising';
export const POST_TYPE_BRANDED_LONGFORM = 'brandedLongform';
export const POST_TYPE_LANDING = 'landing';
export const SUBTYPE_LONGFORM = 13;

export const ROLE_ADMINISTRATOR = 'ROLE_ADMINISTRATOR';
export const ROLE_DIRECTOR = 'ROLE_DIRECTOR';
export const ROLE_EDITOR = 'ROLE_EDITOR';
export const ROLE_COORDINATOR = 'ROLE_COORDINATOR';
export const ROLE_COLABORADOR = 'ROLE_COLABORADOR';
export const ROLE_BRANDED_COLLABORATOR = 'ROLE_BRANDED_COLLABORATOR';
export const ROLE_BRANDED_COORDINATOR = 'ROLE_BRANDED_COORDINATOR';
export const ROLE_FICHA_EDITOR = 'ROLE_FICHA_EDITOR';
export const ROLE_SEO = 'ROLE_SEO';
export const ROLE_ECOMMERCE_DIRECTOR = 'ROLE_ECOMMERCE_DIRECTOR';
export const ROLE_PROGRAMMATIC = 'ROLE_PROGRAMMATIC';

export const MANAGER_ROLES = [ROLE_ADMINISTRATOR, ROLE_DIRECTOR, ROLE_SEO];

export const LONGFORM_POST_TYPES = [
  POST_TYPE_LONGFORM,
  POST_TYPE_BRANDED_LONGFORM,
];

const ALL_TYPES = [
  POST_TYPE_NORMAL,
  POST_TYPE_BRANDED_LONGFORM,
  POST_TYPE_LONGFORM,
  POST_TYPE_BRANDED,
  POST_TYPE_BRAND_ARTICLE,
  POST_TYPE_BRAND_ADVERTISING,
  POST_TYPE_LANDING,
];

export const ALL_BRANDED_TYPES = [
  POST_TYPE_BRANDED_LONGFORM,
  POST_TYPE_BRANDED,
  POST_TYPE_BRAND_ARTICLE,
  POST_TYPE_BRAND_ADVERTISING,
];

export const ALL_COORDINATORS = [
  ROLE_EDITOR,
  ROLE_BRANDED_COORDINATOR,
  ROLE_COORDINATOR,
  ROLE_DIRECTOR,
  ROLE_SEO,
  ROLE_ECOMMERCE_DIRECTOR,
];

export const ALL_NON_BRANDED_ROLES = [
  ROLE_EDITOR,
  ROLE_COLABORADOR,
  ROLE_COORDINATOR,
  ROLE_DIRECTOR,
  ROLE_SEO,
  ROLE_ECOMMERCE_DIRECTOR,
];

const ALL_BRANDED_ROLES = [ROLE_BRANDED_COORDINATOR, ROLE_BRANDED_COLLABORATOR];

export const ALL_ROLES = [...ALL_BRANDED_ROLES, ...ALL_NON_BRANDED_ROLES];

export const ALL_EDITORS = [
  ...ALL_BRANDED_ROLES,
  ROLE_ADMINISTRATOR,
  ROLE_DIRECTOR,
  ROLE_EDITOR,
  ROLE_COORDINATOR,
  ROLE_COLABORADOR,
];

export const viewPermissions = {
  ArticleType: {
    roles: ALL_ROLES,
    types: [POST_TYPE_NORMAL],
  },
  SponsoredContent: {
    roles: [
      ROLE_BRANDED_COORDINATOR,
      ROLE_EDITOR,
      ROLE_DIRECTOR,
      ROLE_COORDINATOR,
      ROLE_SEO,
      ROLE_ECOMMERCE_DIRECTOR,
    ],
    types: [POST_TYPE_BRAND_ARTICLE, POST_TYPE_LANDING],
  },
  SponsoredCheckbox: {
    roles: ALL_ROLES,
    types: [POST_TYPE_LANDING],
  },
  SponsorName: {
    roles: ALL_ROLES,
    types: [POST_TYPE_BRAND_ARTICLE, POST_TYPE_LANDING],
  },
  AdvancedOptions: {
    roles: [...ALL_COORDINATORS, ROLE_COLABORADOR],
    types: ALL_TYPES,
  },
  CountriesFormOptions: {
    roles: [...ALL_COORDINATORS, ROLE_COLABORADOR],
    types: ALL_TYPES,
  },
  OtherCategories: {
    roles: [...ALL_COORDINATORS, ROLE_COLABORADOR],
    types: [POST_TYPE_NORMAL, POST_TYPE_BRAND_ARTICLE],
  },
  PostScheduler: {
    ROLE_EDITOR: ALL_TYPES,
    ROLE_BRANDED_COORDINATOR: ALL_BRANDED_TYPES,
    ROLE_COORDINATOR: ALL_TYPES,
    ROLE_DIRECTOR: ALL_TYPES,
    ROLE_ADMINISTRATOR: ALL_TYPES,
    ROLE_SEO: ALL_TYPES,
    ROLE_ECOMMERCE_DIRECTOR: ALL_TYPES,
  },
  Categories: {
    roles: [...ALL_COORDINATORS, ROLE_COLABORADOR],
    types: [
      POST_TYPE_NORMAL,
      POST_TYPE_BRANDED,
      POST_TYPE_BRAND_ARTICLE,
      POST_TYPE_BRAND_ADVERTISING,
      POST_TYPE_LANDING,
    ],
  },
  SpecialPost: {
    roles: ALL_NON_BRANDED_ROLES,
    types: [POST_TYPE_NORMAL],
  },
  Tags: {
    roles: [...ALL_COORDINATORS, ROLE_COLABORADOR],
    types: ALL_TYPES,
  },
  SensitivePost: {
    roles: [...ALL_COORDINATORS, ROLE_COLABORADOR],
    types: [
      POST_TYPE_NORMAL,
      POST_TYPE_BRANDED,
      POST_TYPE_BRAND_ARTICLE,
      POST_TYPE_LANDING,
    ],
  },
  PublishToHomePage: {
    roles: [...ALL_COORDINATORS, ROLE_COLABORADOR],
    types: [POST_TYPE_NORMAL, POST_TYPE_LANDING],
  },
  UpdateModifiedGmt: {
    roles: [
      ROLE_COORDINATOR,
      ROLE_DIRECTOR,
      ROLE_BRANDED_COORDINATOR,
      ROLE_BRANDED_COLLABORATOR,
      ROLE_SEO,
      ROLE_ECOMMERCE_DIRECTOR,
    ],
    types: [
      POST_TYPE_NORMAL,
      POST_TYPE_BRANDED,
      POST_TYPE_BRAND_ARTICLE,
      POST_TYPE_BRAND_ADVERTISING,
      POST_TYPE_LANDING,
    ],
  },
  Hook: {
    roles: [
      ROLE_COORDINATOR,
      ROLE_DIRECTOR,
      ROLE_BRANDED_COORDINATOR,
      ROLE_BRANDED_COLLABORATOR,
      ROLE_SEO,
      ROLE_ECOMMERCE_DIRECTOR,
    ],
    types: [
      POST_TYPE_NORMAL,
      POST_TYPE_BRANDED,
      POST_TYPE_BRAND_ARTICLE,
      POST_TYPE_BRAND_ADVERTISING,
      POST_TYPE_LANDING,
    ],
  },
  TWTextBox: {
    roles: [...ALL_COORDINATORS, ROLE_COLABORADOR],
    types: [
      POST_TYPE_NORMAL,
      POST_TYPE_BRANDED,
      POST_TYPE_BRAND_ARTICLE,
      POST_TYPE_LANDING,
    ],
  },
  FBTextBox: {
    roles: [...ALL_COORDINATORS, ROLE_COLABORADOR],
    types: [POST_TYPE_NORMAL, POST_TYPE_LANDING],
  },
  CreateTag: {
    roles: [
      ROLE_EDITOR,
      ROLE_BRANDED_COORDINATOR,
      ROLE_COORDINATOR,
      ROLE_DIRECTOR,
      ROLE_SEO,
      ROLE_ECOMMERCE_DIRECTOR,
    ],
    types: ALL_TYPES,
  },
  AuthorName: {
    roles: [
      ROLE_EDITOR,
      ROLE_BRANDED_COORDINATOR,
      ROLE_COORDINATOR,
      ROLE_DIRECTOR,
      ROLE_SEO,
      ROLE_ECOMMERCE_DIRECTOR,
    ],
    types: [
      POST_TYPE_NORMAL,
      POST_TYPE_BRANDED,
      POST_TYPE_BRAND_ARTICLE,
      POST_TYPE_BRAND_ADVERTISING,
    ],
  },
  AuthorVisibilty: {
    roles: [
      ROLE_EDITOR,
      ROLE_BRANDED_COORDINATOR,
      ROLE_COORDINATOR,
      ROLE_DIRECTOR,
      ROLE_SEO,
      ROLE_COLABORADOR,
      ROLE_ECOMMERCE_DIRECTOR,
    ],
    types: [POST_TYPE_NORMAL],
  },
  Promotional: {
    roles: [...ALL_BRANDED_ROLES, ROLE_ECOMMERCE_DIRECTOR],
    types: [
      POST_TYPE_BRANDED,
      POST_TYPE_BRAND_ARTICLE,
      POST_TYPE_BRAND_ADVERTISING,
    ],
  },
  CommentStatus: {
    roles: [
      ...ALL_BRANDED_ROLES,
      ROLE_SEO,
      ROLE_DIRECTOR,
      ROLE_EDITOR,
      ROLE_COORDINATOR,
      ROLE_ECOMMERCE_DIRECTOR,
    ],
    types: [POST_TYPE_NORMAL, POST_TYPE_BRANDED, POST_TYPE_BRAND_ARTICLE],
  },
  PivotExternalArticle: {
    roles: ALL_ROLES,
    types: [POST_TYPE_NORMAL],
  },
  PrimaryContent: {
    roles: ALL_ROLES,
    types: [
      POST_TYPE_NORMAL,
      POST_TYPE_BRANDED,
      POST_TYPE_BRAND_ARTICLE,
      POST_TYPE_BRAND_ADVERTISING,
    ],
  },
  Reindex: {
    roles: [
      ROLE_EDITOR,
      ROLE_COORDINATOR,
      ROLE_DIRECTOR,
      ROLE_SEO,
      ROLE_ECOMMERCE_DIRECTOR,
    ],
    types: [POST_TYPE_NORMAL],
  },
  Rating: {
    roles: ALL_ROLES,
    types: [
      POST_TYPE_NORMAL,
      POST_TYPE_BRANDED,
      POST_TYPE_BRAND_ARTICLE,
      POST_TYPE_BRAND_ADVERTISING,
    ],
  },
  ContentTrain: {
    roles: ALL_ROLES,
    types: [POST_TYPE_LANDING],
  },
  DesignMode: {
    roles: [ROLE_COORDINATOR, ROLE_DIRECTOR, ROLE_SEO, ROLE_ECOMMERCE_DIRECTOR],
    types: [POST_TYPE_LANDING],
  },
  visibility: {
    roles: [
      ROLE_ADMINISTRATOR,
      ROLE_COORDINATOR,
      ROLE_DIRECTOR,
      ROLE_SEO,
      ROLE_ECOMMERCE_DIRECTOR,
    ],
    types: [POST_TYPE_LANDING],
  },
  Singular: {
    roles: ALL_ROLES,
    types: [POST_TYPE_LANDING],
  },
  EvergreenContent: {
    roles: [
      ROLE_EDITOR,
      ROLE_COORDINATOR,
      ROLE_DIRECTOR,
      ROLE_SEO,
      ROLE_ECOMMERCE_DIRECTOR,
    ],
    types: [POST_TYPE_NORMAL],
  },
  AlternativeAmp: {
    roles: [ROLE_ADMINISTRATOR],
    types: ALL_TYPES,
  },
};

export const defaultCommentStatus = {
  normal: 'open',
  club: 'open',
  slideshow: 'open',
  brandedLongform: 'closed',
  longform: 'closed',
  branded: 'open',
  brandArticle: 'open',
  brandAdvertising: 'open',
  landing: 'closed',
};

export const initialMeta = {
  homepage: { content: '' },
  index: '',
  sponsor: { name: '', image: '', tracker: '' },
  css: { skinName: '' },
  seo: { title: '', description: '' },
  showSocialShareButtons: false,
  microsite: {
    name: '',
    gaSnippet: '',
    showWSLLogo: true,
    showSocialButtons: true,
  },
  author: { showAuthorInfo: false },
  social: {
    twitter: '',
    facebook: '',
  },
  footer: { hideFooter: false, content: '' },
  showDate: false,
  promotional: false,
};

export const initialPublishRegions = [
  'ES',
  'US',
  'MX',
  'PE',
  'AR',
  'CL',
  'EC',
  'CR',
  'CO',
  'CEA',
  'GB',
  'ROW',
];

export const postTypePermissions = {
  normal: {
    roles: [...ALL_NON_BRANDED_ROLES, ROLE_BRANDED_COORDINATOR],
  },
  branded: {
    roles: [...ALL_BRANDED_ROLES, ROLE_SEO],
  },
  brandArticle: {
    roles: [
      ...ALL_BRANDED_ROLES,
      ROLE_SEO,
      ROLE_COORDINATOR,
      ROLE_DIRECTOR,
      ROLE_ECOMMERCE_DIRECTOR,
    ],
  },
  brandAdvertising: {
    roles: ALL_BRANDED_ROLES,
  },
  landing: {
    roles: [...ALL_NON_BRANDED_ROLES, ROLE_BRANDED_COORDINATOR],
  },
};

export const DOM_PURIFY_CONFIG = {
  KEEP_CONTENT: false,
  ADD_TAGS: ['iframe', 'script'],
  FORCE_BODY: true,
  ADD_ATTR: [
    'async',
    'charset',
    'defer',
    'target',
    'onclick',
    'onchange',
    'onload',
    'onmouseover',
    'onmouseout',
    'onkeydown',
  ],
};

export const componentNames = {
  fichaCompra: 'ficha de compra',
  contentTrain: 'content train',
  fichaDeReview: 'review',
  pivotInstagram: 'pivot instagram',
  pivot: 'pivot artículo',
  pivotEcommerce: 'pivot product',
  pivotExternalArticle: 'pivot artículo externo',
  pivotNewsletter: 'pivot newsletter',
  pivotFlipboard: 'pivot flipboard',
};

export const ERROR_INVALID_DATE = 'Error: esta fecha ya ha pasado';
export const ERROR_INVALID_DATE_FORMAT =
  'Error: usa el formato DD/MM/YYYY H:mm';
export const SAVING_DATA_ERROR_WARNING = 'Error occured while saving data';

export const initialCrop = {
  square: {
    aspect: 1,
    unit: '%',
    x: 0,
    y: 0,
    height: 100,
    validate: false,
  },
  golden: {
    aspect: 1.5,
    unit: '%',
    x: 0,
    y: 0,
    height: 100,
    validate: false,
  },
  panoramic: {
    aspect: 2.618,
    unit: '%',
    x: 0,
    y: 20,
    width: 100,
    validate: false,
  },
};

export const initCrop = {
  square: {
    validate: false,
  },
  golden: {
    validate: false,
  },
  panoramic: {
    validate: false,
  },
};

export const evergreenCategories = {
  // vitonica: [
  //   'alimentos-funcionales',
  //   'entrenamiento',
  //   'prevencion',
  //   'equipamiento',
  //   'carrera',
  //   'recetas-saludables',
  //   'cosmetica',
  //   'ciclismo',
  //   'aerobic',
  //   'pilates',
  //   'grasas',
  //   'minerales',
  //   'proteinas',
  // ],
  // directoalpaladar: [
  //   'restaurantes',
  //   'curso-de-cocina',
  //   'otros',
  //   'recetas-con-thermomix',
  //   'cultura-gastronomica',
  //   'recetario',
  //   'otras-bebidas',
  //   'tapas-y-pinchos',
  // ],
  compradiccion: ['guia-de-compras'],
};

export const MAX_GIF_SIZE = 307200;
